import React from "react"
import styled from "styled-components"
import { breakpoints } from "~/styles/breakpoints"
import SEO from "~/components/seo"
import Header from "~/components/Header"
import Container from "~/components/Container"
import { Intro, IntroText, IntroImage } from "~/components/Layout"
import {
  PrimaryTitle,
  SecondaryTitle,
  Lead,
  Paragraph,
  SmallParagraph,
} from "~/components/Typography"
import ReactMarkdown from "react-markdown"
import pageData from "../../content/pages/books.json"

export default () => {
  const { metaTitle, metaDescription, metaImage, title, lead, books } = pageData

  return (
    <>
      <SEO title={metaTitle} description={metaDescription} image={metaImage} />
      <Header />
      <Wrapper>
        <Intro gradient="#d8e3e8" gradientFixed>
          <Container>
            <Title>{title}</Title>
            <IntroText>
              <Lead>
                <ReactMarkdown
                  children={lead}
                  unwrapDisallowed
                />
              </Lead>
              {books.map(({ title, description, smallText }, index) => (
                <React.Fragment key={index}>
                  <SecondaryTitle>
                    <i>{title}</i>
                  </SecondaryTitle>
                  <Paragraph>
                    <ReactMarkdown
                      children={description}
                      disallowedElements={["p"]}
                      unwrapDisallowed
                    />
                  </Paragraph>
                  {smallText && <SmallParagraph>{smallText}</SmallParagraph>}
                </React.Fragment>
              ))}
            </IntroText>
            <IntroImage
              src="/images/book-cover.jpg"
              srcSet="/images/book-cover@2x.jpg 2x"
              loading="lazy"
            />
          </Container>
        </Intro>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div``

const Title = styled(PrimaryTitle)`
  margin-bottom: 50px;
  background: -webkit-linear-gradient(#01947d, #395489);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    margin-bottom: 30px;
  }
`
